// /* eslint-disable */
import React from 'react';
import {Form, Input, Button, Col, Row, message, Select, Divider, InputNumber} from 'antd';
import axios from "axios";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

class AddIceCream extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referenceOptions: [],
            icerows: [{ key: uuidv4(), ICE_ROW_ITEM_ID: null, ICE_QUANTITY: null }],
            rows: [{ key: uuidv4(), ROW_ITEM_ID: null, QUANTITY: null }],
        };

        this.formRef = React.createRef();
    }

    async componentDidMount() {
        try {
            const referenceOptions = await this.fetchReferenceOptions();
            this.setState({ referenceOptions });
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsForReference');
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                label: ref.STOCK > 0 ? `${ref.CODE} - ${ref.NAME} - ( ${ref.STOCK} Units )` : `${ref.CODE} - ${ref.NAME} - (Out of Stock)`,
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    addRowIce = () => {
        this.setState((prevState) => {
            if (prevState.icerows.length < 10) {
                return {
                    icerows: [...prevState.icerows, { key: uuidv4(), ICE_ROW_ITEM_ID: null, ICE_QUANTITY: null }],
                };
            }
            return prevState;
        });
    };

    removeRowIce = (key) => {
        this.setState((prevState) => {
            if (prevState.icerows.length > 1) {
                return { icerows: prevState.icerows.filter((row) => row.key !== key) };
            }
            return prevState;
        });
    };

    addRow = () => {
        this.setState((prevState) => {
            if (prevState.rows.length < 10) {
                return {
                    rows: [...prevState.rows, { key: uuidv4(), ROW_ITEM_ID: null, QUANTITY: null }],
                };
            }
            return prevState;
        });
    };

    removeRow = (key) => {
        this.setState((prevState) => {
            if (prevState.rows.length > 0) {
                return { rows: prevState.rows.filter((row) => row.key !== key) };
            }
            return prevState;
        });
    };

    renderIceCreamRow = (row, index) => {
        const { referenceOptions } = this.state;
        return (
            <Row key={row.key} gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        name={['icerows', row.key, 'ICE_ROW_ITEM_ID']}
                        label={`Ice Cream ${index + 1}`}
                        rules={[{ required: true, message: 'Please select reference' }]}
                    >
                        <Select
                            placeholder="Select Reference"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.title?.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {referenceOptions.map((option) => (
                                <Option key={option.value} value={option.value} title={option.label}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={9}>
                    <Form.Item
                        name={['icerows', row.key, 'ICE_QUANTITY']}
                        label="No of Scoops"
                        rules={[{ required: true, message: 'Please enter quantity' }]}
                    >
                        <Input type="number" placeholder="Enter no of scoops" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={3}>
                    <Button danger onClick={() => this.removeRowIce(row.key)}
                            style={{ marginTop: '32px' }}
                    >
                        -
                    </Button>
                </Col>
            </Row>
        );
    };

    renderItemRow = (row, index) => {
        const { referenceOptions } = this.state;
        return (
            <Row key={row.key} gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        name={['rows', row.key, 'ROW_ITEM_ID']}
                        label={`Item ${index + 1}`}
                        rules={[{ required: true, message: 'Please select reference' }]}
                    >
                        <Select
                            placeholder="Select Reference"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                option.title?.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {referenceOptions.map((option) => (
                                <Option key={option.value} value={option.value} title={option.label}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={9}>
                    <Form.Item
                        name={['rows', row.key, 'QUANTITY']}
                        label="Quantity"
                        rules={[{ required: true, message: 'Please enter quantity' }]}
                    >
                        <Input type="number" placeholder="Enter quantity" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={3}>
                    <Button danger onClick={() => this.removeRow(row.key)}
                            style={{ marginTop: '32px' }}
                    >
                        -
                    </Button>
                </Col>
            </Row>
        );
    };

    handleSubmit = async (values) => {
        try {
            let rememberedUser = Cookies.get('rememberedUser');
            let USER_ID = null;

            if (rememberedUser) {
                rememberedUser = JSON.parse(rememberedUser);
                USER_ID = rememberedUser.USER_ID;
            }

            const checkName = {
                CODE: `IC-${values.CODE}`,
            };

            // Check for duplicate name
            const checkDuplicateName = await axios.post('https://iontheway.store/api/checkForDuplicateName', checkName);

            if (checkDuplicateName.data.duplicate) {
                message.error('Item code already exists');
                return;
            }

            // Get the actual form values to ensure proper structure
            const formValues = this.formRef.current.getFieldsValue();

            // Ensure `icerows` and `rows` are arrays
            const iceCreamSection = Object.values(formValues.icerows || {}).map(row => [row.ICE_ROW_ITEM_ID, row.ICE_QUANTITY]);
            const otherItemsSection = Object.values(formValues.rows || {}).map(row => [row.ROW_ITEM_ID, row.QUANTITY]);

            const itemRecipe = {
                iceCreams: iceCreamSection,
                otherItems: otherItemsSection,
            };

            const updatedValues = {
                NAME: values.NAME,
                CODE: `IC-${values.CODE}`,
                SELLING_PRICE: values.SELLING_PRICE,
                UNIT: 'PCS',
                ITEM_RECIPE: JSON.stringify(itemRecipe), // Save as JSON string
                CREATED_BY: USER_ID,
                IS_ACTIVE: 1,
            };

            console.log('Updated Values:', updatedValues);

            const response = await axios.post('https://iontheway.store/api/addicecream', updatedValues);
            if (response.data.success) {
                message.success('Ice Cream Recipe added successfully');
                this.props.onClose();
                this.props.refreshTable();
                this.formRef.current.resetFields();
            } else {
                message.error('Error adding Ice Cream Recipe');
            }
        } catch (error) {
            console.error('Error adding Ice Cream Recipe:', error);
            message.error('Internal server error');
        }
    };


    render() {
        return (
            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="NAME"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter name' }]}
                        >
                            <Input placeholder="Enter name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="CODE"
                            label="Code"
                            rules={[{ required: true, message: 'Please enter code' }]}
                        >
                            <Input
                                addonBefore="IC -"
                                placeholder="Enter code"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            label="Selling Price (Rs.)"
                            name="SELLING_PRICE"
                            defaultValue={0}
                        >
                            <InputNumber step={0.01} placeholder="0.00" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider>Ice Cream Section</Divider>
                {this.state.icerows.map((row, index) => this.renderIceCreamRow(row, index))}
                {this.state.icerows.length < 10 && (
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Button type="default" onClick={this.addRowIce}>+</Button>
                        </Col>
                    </Row>
                )}

                <Divider>Other Items Section</Divider>
                {this.state.rows.map((row, index) => this.renderItemRow(row, index))}
                {this.state.rows.length < 10 && (
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Button type="default" onClick={this.addRow}>+</Button>
                        </Col>
                    </Row>
                )}

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }}>
                            Add Ice Cream Recipe
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default AddIceCream;
