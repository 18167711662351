// /* eslint-disable */
import React, { Component } from 'react';
import { Button, Card, Col, Divider, Input, message, Modal, Popconfirm, Row, Select, Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import AddIceCream from './AddIceCream';
import UpdateIceCream from './UpdateIceCream';

const { Option } = Select;
const { Search } = Input;

class IceCream extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableData: [],
            filteredTableData: [],
            isAddCustomerModalVisible: false,
            isUpdateCustomerModalVisible: false,
            selectedCustomer: null,
            referenceOptions: [],
            isViewItemModalVisible: false,
            selectedRefferenceItem: null,
        };

        // Bind methods
        this.handleUpdateShow = this.handleUpdateShow.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getAllCustomersHT = this.getAllCustomersHT.bind(this);
        this.toggleAddCustomerModal = this.toggleAddCustomerModal.bind(this);
        this.toggleUpdateCustomerModal = this.toggleUpdateCustomerModal.bind(this);
        this.handleAddCustomer = this.handleAddCustomer.bind(this);
        this.handleUpdateCustomer = this.handleUpdateCustomer.bind(this);
    }

    async componentDidMount() {
        this.getAllCustomersHT();
        const referenceOptions = await this.fetchReferenceOptions();
        this.setState({ referenceOptions });
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsForReference');
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                label: ref.CODE,
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    handleSearch = (value) => {
        const { tableData } = this.state;

        const filteredData = tableData.filter((record) => {
            return record.NAME.toLowerCase().includes(value.toLowerCase());
        });

        this.setState({
            filteredTableData: filteredData,
        });

        if (filteredData.length === 0) {
            message.info('No Ice Cream Recipes found');
        }
    };

    handleUpdateShow(row) {
        this.setState({
            selectedCustomer: row,
            isUpdateCustomerModalVisible: true,
        });
    }

    handleDelete = async (Id) => {
        try {
            const response = await axios.post('https://iontheway.store/api/deactivateIceCream', {
                ITEM_ID: Id,
            });

            if (response.data.success) {
                message.success('Ice Cream Recipe deleted successfully');
                this.getAllCustomersHT();
            } else {
                message.error('Failed to delete Ice Cream Recipe');
            }
        } catch (error) {
            console.error('Error deleting Ice Cream Recipe:', error);
            message.error('Internal server error');
        }
    };

    async getAllCustomersHT() {
        this.setState({ loading: true });

        try {
            const response = await axios.post('https://iontheway.store/api/getAllIceCreams');

            if (response.data.success) {
                const customers = response.data.result;
                console.log('customers', customers);

                this.setState({
                    tableData: customers,
                });
                console.log('tableData', this.state.tableData);
            }
        } catch (error) {
            console.error('Error fetching Ice Cream Recipes:', error);
        } finally {
            this.setState({ loading: false });
        }
    }

    toggleAddCustomerModal() {
        this.setState({
            isAddCustomerModalVisible: !this.state.isAddCustomerModalVisible,
        });
    }

    toggleUpdateCustomerModal() {
        this.setState({
            isUpdateCustomerModalVisible: !this.state.isUpdateCustomerModalVisible,
        });
    }

    handleAddCustomer(values) {
        this.toggleAddCustomerModal();
    }

    handleUpdateCustomer() {
        this.getAllCustomersHT();
        this.toggleUpdateCustomerModal();
    }

    render() {
        const buttonStyle = {
            width: '50px',
            height: '50px',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };

        return (
            <>
                <div className="tabled">
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                className="criclebox tablespace mb-24"
                                title="Ice Cream Recipes"
                                extra={
                                    <>
                                        <Search
                                            placeholder="Search by Item Name"
                                            onSearch={this.handleSearch}
                                            style={{ width: 300, margin: '10px' }}
                                        />
                                        <Button className="primary" onClick={this.toggleAddCustomerModal} style={{ margin: '10px' }}>
                                            <PlusOutlined /> Add Ice Cream Recipe
                                        </Button>
                                    </>
                                }
                            >
                                <div className="table-responsive">
                                    <Table
                                        className="ant-border-space"
                                        size="small"
                                        style={{ margin: '15px' }}
                                        rowKey="ITEM_ID"
                                        columns={[
                                            {
                                                title: 'Item Code',
                                                dataIndex: 'CODE',
                                            },
                                            {
                                                title: 'Item Name',
                                                dataIndex: 'NAME',
                                            },

                                            {
                                                title: 'Ice Cream Items',
                                                render: (row) => {
                                                    const iceCreams = row.ITEM_RECIPE?.iceCreams || [];
                                                    return iceCreams.length > 0 ? (
                                                        <div>
                                                            {iceCreams.map((item) => (
                                                                <div key={item.ITEM_ID}>
                                                                    <strong>{item.NAME}</strong> - {item.QUANTITY} Units
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <span>No Ice Cream Items</span>
                                                    );
                                                },
                                            },
                                            {
                                                title: 'Other Items',
                                                render: (row) => {
                                                    const otherItems = row.ITEM_RECIPE?.otherItems || [];
                                                    return otherItems.length > 0 ? (
                                                        <div>
                                                            {otherItems.map((item) => (
                                                                <div key={item.ITEM_ID}>
                                                                    <strong>{item.NAME}</strong> - {item.QUANTITY} Units
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <span>No Other Items</span>
                                                    );
                                                },
                                            },

                                            {
                                                title: 'Action',
                                                width: '120px',
                                                align: 'center',
                                                render: (row) => (
                                                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Tooltip title="Edit">
                                                            <Button
                                                                type="primary"
                                                                icon={<EditOutlined />}
                                                                size="large"
                                                                style={buttonStyle}
                                                                onClick={() => this.handleUpdateShow(row)}
                                                            />
                                                        </Tooltip>
                                                        <Divider type="vertical" style={{ height: '50px', display: 'flex', alignItems: 'center' }} />
                                                        <Tooltip title="Delete">
                                                            <Popconfirm
                                                                title="Are you sure you want to delete this item?"
                                                                onConfirm={() => this.handleDelete(row.ITEM_ID)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    danger
                                                                    icon={<DeleteOutlined />}
                                                                    size="large"
                                                                    style={buttonStyle}
                                                                />
                                                            </Popconfirm>
                                                        </Tooltip>
                                                    </span>
                                                ),
                                            },
                                        ]}
                                        dataSource={
                                            this.state.filteredTableData.length > 0
                                                ? this.state.filteredTableData
                                                : this.state.tableData
                                        }
                                        pagination={true}
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Modal
                    title="Add Ice Cream Recipe"
                    visible={this.state.isAddCustomerModalVisible}
                    onCancel={this.toggleAddCustomerModal}
                    width={800}
                    footer={null}
                >
                    <AddIceCream
                        onClose={this.toggleAddCustomerModal}
                        refreshTable={this.getAllCustomersHT}
                    />
                </Modal>

                <Modal
                    title="Update Ice Cream Recipe"
                    visible={this.state.isUpdateCustomerModalVisible}
                    onCancel={this.toggleUpdateCustomerModal}
                    width={800}
                    footer={null}
                >
                    {this.state.selectedCustomer && (
                        <UpdateIceCream
                            initialValues={this.state.selectedCustomer}
                            key={this.state.selectedCustomer.ITEM_ID}
                            onClose={this.toggleUpdateCustomerModal}
                            refreshTable={this.getAllCustomersHT}
                        />
                    )}
                </Modal>
            </>
        );
    }
}

export default IceCream;
