import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import Main from "./components/layout/Main";
import Login from "./pages/login/LoginPage";
import CreteItems from "./pages/Store/CreateItems/CreateItems";


import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import EditStock from "./pages/Store/EditStock/EditStock";
import AddTransactionForm from "./pages/Store/AddTransactionForm/AddTransactionForm";
import EditTransactions from "./pages/Store/EditTransactions/EditTransactions";
import Cookies from "js-cookie";
import MakeJuice from "./pages/Store/MakeJuice/MakeJuice";
import Reports from "./pages/Store/Reports/Reports";
import Return from "./pages/Store/Returns/Return";
import IceCream from "./pages/Store/IceCreams/IceCream";


function App() {
  let rememberedUser = Cookies.get("rememberedUser");
  const is_cookie_set = Boolean(rememberedUser);

  let ROLE1 = "USER";

  if (rememberedUser) {
    rememberedUser = JSON.parse(rememberedUser);
    const { ROLE } = rememberedUser;
    ROLE1 = ROLE;
  }

  useEffect(() => {
    if (is_cookie_set && window.location.pathname === '/') {
      // Redirect to '/' if no valid cookie is set and the path is not '/'
      window.location.href = "/dashboard";
    }
  }, [is_cookie_set]);

  return (
      <div className="App">
        <Switch>
          <Route path="/" exact component={Login} />
          <Main>
            <Route exact path="/dashboard" component={Home} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/create-items" component={CreteItems} />
            <Route exact path="/edit-stock" component={EditStock} />
            <Route exact path="/add-transactions" component={AddTransactionForm} />
            {/*<Route exact path="/customers-store1" component={Customers} />*/}
            <Route exact path="/edit-transactions" component={EditTransactions} />
            <Route exact path="/make-juice" component={MakeJuice} />
            <Route exact path="/ice-creames" component={IceCream} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/return" component={Return} />
            {/*<Route exact path="/add-payments-store1" component={AddPaymentsForm} />*/}
            {/*<Redirect to="/dashboard" />*/}
          </Main>
        </Switch>
      </div>
  );
}

export default App;
