// /* eslint-disable */
import React from 'react';
import { Form, Input, Button, Col, Row, message, Select, Divider, InputNumber } from 'antd';
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

class UpdateIceCream extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referenceOptions: [],
            icerows: [],
            rows: [],
        };

        this.formRef = React.createRef();
    }

    async componentDidMount() {
        try {
            const referenceOptions = await this.fetchReferenceOptions();
            this.setState({ referenceOptions }, this.setInitialValues);
        } catch (error) {
            console.error('Error fetching reference options:', error);
        }
    }

    async fetchReferenceOptions() {
        try {
            const response = await axios.post('https://iontheway.store/api/getItemsForReference');
            return response.data.result.map((ref) => ({
                value: ref.ITEM_ID,
                label: ref.STOCK > 0 ? `${ref.CODE} - ${ref.NAME} - ( ${ref.STOCK} Units )` : `${ref.CODE} - ${ref.NAME} - (Out of Stock)`
            }));
        } catch (error) {
            console.error('Error fetching reference options:', error);
            return [];
        }
    }

    setInitialValues = () => {
        const { initialValues } = this.props;
        if (initialValues) {
            const { ITEM_RECIPE, NAME, CODE, SELLING_PRICE } = initialValues;

            // Parse and set rows and icerows from ITEM_RECIPE
            const parsedRecipe = ITEM_RECIPE || { iceCreams: [], otherItems: [] };
            console.log('parsedRecipe:', parsedRecipe);
            console.log('parsedRecipe.iceCreams:', parsedRecipe.iceCreams);

            this.setState({
                icerows: parsedRecipe.iceCreams.map(({ ITEM_ID, NAME, QUANTITY }) => ({
                    key: uuidv4(),
                    ICE_ROW_ITEM_ID: ITEM_ID,
                    ICE_QUANTITY: QUANTITY,
                })),
                rows: parsedRecipe.otherItems.map(({ ITEM_ID, NAME, QUANTITY }) => ({
                    key: uuidv4(),
                    ROW_ITEM_ID: ITEM_ID,
                    QUANTITY: QUANTITY,
                })),
            }, () => {
                // Callback to log the updated state
                console.log('icerows (updated):', this.state.icerows);
                console.log('rows (updated):', this.state.rows);
            });

            this.formRef.current.setFieldsValue({
                NAME,
                CODE: CODE.replace("IC-", ""),
                SELLING_PRICE,
            });
        }
    };


    addRowIce = () => {
        this.setState((prevState) => ({
            icerows: [...prevState.icerows, { key: uuidv4(), ICE_ROW_ITEM_ID: null, ICE_NAME: null, ICE_QUANTITY: null }],
        }));
    };

    removeRowIce = (key) => {
        this.setState((prevState) => ({
            icerows: prevState.icerows.filter((row) => row.key !== key),
        }));
    };

    addRow = () => {
        this.setState((prevState) => ({
            rows: [...prevState.rows, { key: uuidv4(), ROW_ITEM_ID: null, ROW_NAME: null, QUANTITY: null }],
        }));
    };

    removeRow = (key) => {
        this.setState((prevState) => ({
            rows: prevState.rows.filter((row) => row.key !== key),
        }));
    };

    handleSubmit = async (values) => {
        try {
            const { initialValues } = this.props;

            // Get the actual form values to ensure proper structure
            const formValues = this.formRef.current.getFieldsValue();

            // Ensure `icerows` and `rows` are arrays
            const iceCreamSection = Object.values(formValues.icerows || {}).map(row => [row.ICE_ROW_ITEM_ID, row.ICE_QUANTITY]);
            const otherItemsSection = Object.values(formValues.rows || {}).map(row => [row.ROW_ITEM_ID, row.QUANTITY]);

            const itemRecipe = {
                iceCreams: iceCreamSection,
                otherItems: otherItemsSection,
            };

            const updatedValues = {
                ITEM_ID: initialValues.ITEM_ID,
                NAME: values.NAME,
                CODE: `IC-${values.CODE}`,
                SELLING_PRICE: values.SELLING_PRICE,
                ITEM_RECIPE: JSON.stringify(itemRecipe), // Save as JSON string

            };

            console.log('updatedValues:', updatedValues);

            const response = await axios.post('https://iontheway.store/api/updateIceCream', updatedValues);

            if (response.data.success) {
                message.success('Ice Cream Recipe updated successfully');
                this.props.onClose();
                this.props.refreshTable();
            } else {
                message.error('Error updating Ice Cream Recipe');
            }
        } catch (error) {
            console.error('Error updating Ice Cream Recipe:', error);
            message.error('Internal server error');
        }
    };

    renderIceCreamRow = (row, index) => {
        console.log('row:', row);
        console.log('index:', index);
        const { referenceOptions } = this.state;

        return (
            <Row key={row.key} gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        name={['icerows', index, 'ICE_ROW_ITEM_ID']} // Link to the correct index
                        label={`Ice Cream ${index + 1}`}
                        initialValue={row.ICE_ROW_ITEM_ID} // Assign initial value
                        rules={[{ required: true, message: 'Please select reference' }]}
                    >
                        <Select
                            placeholder="Select Reference"
                            allowClear
                            showSearch
                        >
                            {referenceOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={9}>
                    <Form.Item
                        name={['icerows', index, 'ICE_QUANTITY']} // Link to the correct index
                        label="No of Scoops"
                        initialValue={row.ICE_QUANTITY} // Assign initial value
                        rules={[{ required: true, message: 'Please enter quantity' }]}
                    >
                        <Input type="number" placeholder="Enter no of scoops" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={3}>
                    <Button
                        style={{ marginTop: '32px' }}
                        danger
                        onClick={() => this.removeRowIce(row.key)}
                    >
                        -
                    </Button>
                </Col>
            </Row>
        );
    };


    renderItemRow = (row, index) => {
        const { referenceOptions } = this.state;

        return (
            <Row key={row.key} gutter={[16, 16]} justify="left" align="top">
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item
                        name={['rows', index, 'ROW_ITEM_ID']} // Use index for consistent mapping
                        label={`Item ${index + 1}`}
                        initialValue={row.ROW_ITEM_ID} // Assign initial value
                        rules={[{ required: true, message: 'Please select reference' }]}
                    >
                        <Select
                            placeholder="Select Reference"
                            allowClear
                            showSearch
                        >
                            {referenceOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={9}>
                    <Form.Item
                        name={['rows', index, 'QUANTITY']} // Use index for consistent mapping
                        label="Quantity"
                        initialValue={row.QUANTITY} // Assign initial value
                        rules={[{ required: true, message: 'Please enter quantity' }]}
                    >
                        <Input type="number" placeholder="Enter quantity" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} lg={3}>
                    <Button
                        style={{ marginTop: '32px' }}
                        danger
                        onClick={() => this.removeRow(row.key)}
                    >
                        -
                    </Button>
                </Col>
            </Row>
        );
    };


    render() {
        return (
            <Form ref={this.formRef} layout="vertical" onFinish={this.handleSubmit}>
                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="NAME"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter name' }]}
                        >
                            <Input placeholder="Enter name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            name="CODE"
                            label="Code"
                            rules={[{ required: true, message: 'Please enter code' }]}
                        >
                            <Input addonBefore="IC -" placeholder="Enter code" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Form.Item
                            label="Selling Price (Rs.)"
                            name="SELLING_PRICE"
                            defaultValue={0}
                        >
                            <InputNumber step={0.01} placeholder="0.00" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider>Ice Cream Section</Divider>
                {this.state.icerows.map((row, index) => this.renderIceCreamRow(row, index))}
                {this.state.icerows.length < 10 && (
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Button type="default" onClick={this.addRowIce}>+</Button>
                        </Col>
                    </Row>
                )}

                <Divider>Other Items Section</Divider>
                {this.state.rows.map((row, index) => this.renderItemRow(row, index))}
                {this.state.rows.length < 10 && (
                    <Row gutter={[16, 16]} justify="left" align="top">
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Button type="default" onClick={this.addRow}>+</Button>
                        </Col>
                    </Row>
                )}

                <Row gutter={[16, 16]} justify="left" align="top">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }}>
                            Update Ice Cream Recipe
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default UpdateIceCream;
